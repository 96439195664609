<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">{{ bread.tipMenuTxt }}</li>
      <li class="breadcrumb-item active" aria-current="page">{{ bread.lightMenuTxt }}</li>
    </ol>
  </nav>
</template>

<script>
import { ref } from "vue";
export default {
  name: "Breadcrumb",
  setup(props) {
    return {
      bread: ref({
        tipMenuTxt: "",
        lightMenuTxt: "",
      }),
    };
  },
  created() {
    this.bread.tipMenuTxt = sessionStorage.getItem("oneName");
    this.bread.lightMenuTxt = sessionStorage.getItem("twoName");
  },
};
</script>
