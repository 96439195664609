import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import axios from "axios";
import VueAxios from "vue-axios";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "../public/font/css/all.min.css";

import "../public/css/style.scss";
const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueAxios, axios);
app.mount("#app");
