<template>
  <Menu />
  <Carousel />
  <div id="container" class="container">
    <div class="row">
      <!-- 关于我们 -->
      <div class="title">
        <h1>关于我们</h1>
        <span class="">ABOUT US</span>
      </div>
      <div class="desc">
        <p v-html="desc"></p>
      </div>
      <!-- 新闻 -->
      <div class="title">
        <h1>企业动态</h1>
        <span class="">Enterprise dynamics</span>
      </div>
      <!-- <div class="row ${1| ,row-cols-2,row-cols-3, auto,justify-content-md-center,|}"> -->
      <div class="col-12 col-md-6">
        <h6
          class="d-flex justify-content-end justify-content-md-start my-3 indexNewsSub pb-2 border-bottom"
        >
          <span></span>
          公司动态
        </h6>
        <template v-for="(item, index) in card" :key="index">
          <div class="indexNewsTit my-2">
            <a :href="'#/NewsInfo?id=' + item.id + '&MenusId=21'">
              · {{ item.Title }}（{{ timeFmt(item.ReleaseTime).substring(5, 10) }}）
            </a>
          </div>
        </template>
      </div>
      <div class="col-12 col-md-6">
        <h6
          class="d-flex justify-content-end justify-content-md-start my-3 indexNewsSub pb-2 border-bottom"
        >
          <span></span>
          媒体聚焦
        </h6>
        <template v-for="(item, index) in media" :key="index">
          <div class="indexNewsTit my-2">
            <a :href="'#/NewsInfo?id=' + item.id + '&MenusId=24'">
              · {{ item.Title }}（{{ timeFmt(item.ReleaseTime).substring(5, 10) }}）
            </a>
          </div>
        </template>
      </div>
      <!-- </div> -->
      <!-- <div class="newsAreaCol"> -->
      <!--  -->
      <!-- <div class="card" v-for="item in card" :key="item">
          <div class="img">
            <a :href="'#/NewsInfo?id=' + item.id + '&MenusId=21'">
              <img :src="item.CoverUrl" class="card-img-top" alt="..." />
            </a>
          </div>
          <div class="card-body">
            <h5 class="card-title newsTit">
              <a :href="'#/NewsInfo?id=' + item.id + '&MenusId=21'">{{ item.Title }}</a>
            </h5>
            <p class="card-text newsDesc">
              {{ item.Abstract }}
            </p>
            <div class="nTime">{{ timeFmt(item.ReleaseTime).substring(0, 10) }}</div>
            <a :href="'#/NewsInfo?id=' + item.id + '&MenusId=21'" class="newsMore"
              ><span class="">查看详情</span><i class="fas fa-arrow-circle-right ms-3"></i
            ></a>
          </div>
        </div> -->
      <!--  -->
      <!-- </div> -->
    </div>

    <div class="row ${1| ,row-cols-2,row-cols-3, auto,justify-content-md-center,|}">
      <!-- 项目概况 -->
      <div class="title">
        <h1>项目概况</h1>
        <span class="">Industry dynamics</span>
        <!-- <h1>党群建设</h1>
      <span class="">Party masses construction</span> -->
      </div>

      <div class="film">
        <div class="card" v-for="(item, index) in proj" :key="index">
          <a class="img" :href="'#/FilmsInfo?id=' + item.id + '&MenusId=25'">
            <img :src="item.CoverUrl" class="card-img-top" alt="..."
          /></a>
          <div class="card-body">
            <h5 class="card-title newsTit">
              <a :href="'#/FilmsInfo?id=' + item.id + '&MenusId=25'">{{ item.Title }}</a>
            </h5>
            <p class="card-text newsDesc">{{ item.beiyong1 }}</p>
            <p class="card-text newsDesc">{{ item.beiyong3 }}</p>
          </div>
        </div>
        <!-- <div class="card" v-for="item in proj" :key="item">
        <div class="img">
          <a :href="'#/FilmsInfo?id=' + item.id + '&MenusId=25'">
            <img :src="item.CoverUrl" class="card-img-top" alt="..." />
          </a>
        </div>
        <div class="card-body">
          <h5 class="card-title newsTit">
            <a :href="'#/FilmsInfo?id=' + item.id + '&MenusId=25'">{{ item.Title }}</a>
          </h5>
          <p class="card-text newsDesc">
            <span class="d-inline-block text-truncate">{{ item.beiyong1 }}</span>
            <span class="d-inline-block text-truncate">{{ item.beiyong3 }}</span>
          </p>
          <div class="nTime">{{ timeFmt(item.ReleaseTime).substring(0, 10) }}</div>
          <a :href="'#/FilmsInfo?id=' + item.id + '&MenusId=25'" class="newsMore"
            ><span class="">查看详情</span><i class="fas fa-arrow-circle-right ms-3"></i
          ></a>
        </div>
      </div> -->
      </div>
      <!-- 友情链接 -->
      <div class="title">
        <h1>友情链接</h1>
        <span class="">Links</span>
      </div>
      <Links />
    </div>
  </div>
  <FooterBanner />
</template>

<script>
// @ is an alias to /src
import { ref } from "vue";
import Menu from "@/components/Menu.vue";
import Carousel from "@/components/Carousel.vue";
import FooterBanner from "@/components/FooterBanner.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Links from "@/components/Links.vue";
export default {
  name: "Home",
  components: {
    Menu,
    Carousel,
    FooterBanner,
    Breadcrumb,
    Links,
  },
  setup(props) {
    return {
      desc: ref(),
      card: ref(),
      media: ref(),
      proj: ref(),
    };
  },
  created() {
    sessionStorage.setItem("one", 8);
    sessionStorage.removeItem("two");
    sessionStorage.removeItem("twoName");
    const app = document.documentElement.clientWidth;
    let w;
    if (app < 768) {
      w = 4;
    } else {
      w = 8;
    }
    // 关于我们
    this.axios({
      method: "get",
      url:
        "https://cms.myhuiyi.com/API/V1/Website/Articles/default.aspx?user=officialWebsite&password=VEFFbCCAPcApCsuKAjNgD1gS5YENnxBc&ClassifysId=0&MenusId=15&ProjectsId=7",
    }).then((res) => {
      this.desc = res.data.data.DataList[0].Details;
    });
    // 公司动态
    this.axios({
      method: "get",
      url:
        "https://cms.myhuiyi.com/API/V1/Website/Articles/default.aspx?user=officialWebsite&password=VEFFbCCAPcApCsuKAjNgD1gS5YENnxBc&ClassifysId=0&MenusId=21&ProjectsId=7&num=5",
    }).then((res) => {
      this.card = res.data.data.DataList;
    });
    // 媒体聚焦
    this.axios({
      method: "get",
      url:
        "https://cms.myhuiyi.com/API/V1/Website/Articles/default.aspx?user=officialWebsite&password=VEFFbCCAPcApCsuKAjNgD1gS5YENnxBc&ClassifysId=0&MenusId=24&ProjectsId=7&num=5",
    }).then((res) => {
      this.media = res.data.data.DataList;
    });
    // 项目概况
    this.axios({
      method: "get",
      url:
        "https://cms.myhuiyi.com/API/V1/Website/Informations/default.aspx?user=officialWebsite&password=VEFFbCCAPcApCsuKAjNgD1gS5YENnxBc&ClassifysId=0&MenusId=25&ProjectsId=7&id=&num=" +
        w,
    }).then((res) => {
      this.proj = res.data.data.DataList;
    });
  },
  methods: {
    timeFmt(value) {
      var date = new Date(value);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      month = month > 9 ? month : "0" + month;
      var day = date.getDate();
      day = day > 9 ? day : "0" + day;
      var hh = date.getHours();
      hh = hh > 9 ? hh : "0" + hh;
      var mm = date.getMinutes();
      mm = mm > 9 ? mm : "0" + mm;
      var ss = date.getSeconds();
      ss = ss > 9 ? ss : "0" + ss;
      var time = year + "-" + month + "-" + day + " " + hh + ":" + mm + ":" + ss;
      return time;
    },
  },
};
</script>
