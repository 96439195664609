<template>
  <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button
        v-for="(item, index) in banner"
        :key="index"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        :data-bs-slide-to="index"
        :class="[index == 0 ? 'active' : '']"
        aria-current="true"
      ></button>
    </div>
    <div class="carousel-inner">
      <a
        v-for="(item, index) in banner"
        :key="index"
        :href="item.link ? item.link : ''"
        target="_blank"
        class="carousel-item"
        :class="[index == 0 ? 'active' : '']"
      >
        <img :src="item.url" class="d-block w-100" alt="..." />
        <!-- <div class="carousel-caption d-none d-md-block">
          <h5>{{ item.title }}</h5>
          <p>{{ item.subTitlt }}</p>
        </div> -->
      </a>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "Carousel",
  data() {
    return {
      banner: ref([]),
    };
  },
  created() {
    this.axios({
      method: "get",
      url:
        "https://cms.myhuiyi.com/API/V1/Website/RotationChart/default.aspx?user=officialWebsite&password=VEFFbCCAPcApCsuKAjNgD1gS5YENnxBc&ClassifysId=9&MenusId=8&ProjectsId=7",
    }).then((res) => {
      let data = res.data.data.DataList;
      for (let i = 0; i < data.length; i++) {
        this.banner.push({
          title: data[i].Title,
          subTitlt: data[i].Subtitle,
          url: data[i].PictureUrl,
          link: data[i].Link,
        });
      }
    });
  },
};
</script>
