import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "曲江影视",
      // keepAlive: true,
    },
    // children:[

    // ]
  },
  {
    path: "/AboutUs",
    name: "AboutUs",
    meta: {
      title: "集团概况",
    },
    component: () => import("../views/AboutUs.vue"),
  },
  {
    path: "/AboutCulture",
    name: "AboutCulture",
    meta: {
      title: "企业文化",
    },
    component: () => import("../views/AboutCulture.vue"),
  },
  {
    path: "/AboutOrganization",
    name: "AboutOrganization",
    meta: {
      title: "组织架构",
    },
    component: () => import("../views/AboutOrganization.vue"),
  },
  {
    path: "/AboutSubsidiary",
    name: "AboutSubsidiary",
    meta: {
      title: "分子公司",
    },
    component: () => import("../views/AboutSubsidiary.vue"),
  },
  {
    path: "/AboutBigNotes",
    name: "AboutBigNotes",
    meta: {
      title: "集团大记事",
    },
    component: () => import("../views/AboutBigNotes.vue"),
  },
  {
    path: "/AboutAward",
    name: "AboutAward",
    meta: {
      title: "获奖信息",
    },
    component: () => import("../views/AboutAward.vue"),
  },
  {
    path: "/AboutTeam",
    name: "AboutTeam",
    meta: {
      title: "团队风采",
    },
    component: () => import("../views/AboutTeam.vue"),
  },
  {
    path: "/DynamicsCompany",
    name: "DynamicsCompany",
    meta: {
      title: "企业动态",
    },
    component: () => import("../views/DynamicsCompany.vue"),
  },
  {
    path: "/DynamicsIndustry",
    name: "DynamicsIndustry",
    meta: {
      title: "行业动态",
    },
    component: () => import("../views/DynamicsIndustry.vue"),
  },
  {
    path: "/DynamicsGroup",
    name: "DynamicsGroup",
    meta: {
      title: "党群建设",
    },
    component: () => import("../views/DynamicsGroup.vue"),
  },
  {
    path: "/DynamicsMedia",
    name: "DynamicsMedia",
    meta: {
      title: "媒体聚焦",
    },
    component: () => import("../views/DynamicsMedia.vue"),
  },
  {
    path: "/Film",
    name: "Film",
    meta: {
      title: "电影",
    },
    component: () => import("../views/Film.vue"),
  },
  {
    path: "/FilmTV",
    name: "FilmTV",
    meta: {
      title: "电视剧",
    },
    component: () => import("../views/FilmTV.vue"),
  },
  {
    path: "/FilmDocumentary",
    name: "FilmDocumentary",
    meta: {
      title: "纪录片",
    },
    component: () => import("../views/FilmDocumentary.vue"),
  },
  {
    path: "/FilmVariety",
    name: "FilmVariety",
    meta: {
      title: "综艺",
    },
    component: () => import("../views/FilmVariety.vue"),
  },
  {
    path: "/FilmWeb",
    name: "FilmWeb",
    meta: {
      title: "网剧网大",
    },
    component: () => import("../views/FilmWeb.vue"),
  },
  {
    path: "/microFilm",
    name: "microFilm",
    meta: {
      title: "微短剧",
    },
    component: () => import("../views/microFilm.vue"),
  },
  {
    path: "/microFilmsInfo",
    name: "microFilmsInfo",
    meta: {
      title: "短剧详情",
    },
    component: () => import("../views/microFilmsInfo.vue"),
  },
  {
    path: "/Business",
    name: "Business",
    meta: {
      title: "业务板块",
    },
    component: () => import("../views/Business.vue"),
  },
  // {
  //   path: "/Special",
  //   name: "Special",
  //   meta: {
  //     title: "专题专栏",
  //   },
  //   component: () => import("../views/Special.vue"),
  // },
  {
    path: "/SpecialWebMange",
    name: "SpecialWebMange",
    meta: {
      title: "网络管理",
    },
    component: () => import("../views/SpecialWebMange.vue"),
  },
  {
    path: "/SpecialLaw",
    name: "SpecialLaw",
    meta: {
      title: "法治建设",
    },
    component: () => import("../views/SpecialLaw.vue"),
  },
  {
    path: "/SafeProduction",
    name: "SafeProduction",
    meta: {
      title: "安全生产",
    },
    component: () => import("../views/SafeProduction.vue"),
  },
  {
    path: "/SpecialIntellectualProperty",
    name: "SpecialIntellectualProperty",
    meta: {
      title: "知识产权",
    },
    component: () => import("../views/SpecialIntellectualProperty.vue"),
  },
  {
    path: "/Special145",
    name: "Special145",
    meta: {
      title: "十四五规划",
    },
    component: () => import("../views/Special145.vue"),
  },
  {
    path: "/SpecialInfo",
    name: "SpecialInfo",
    meta: {
      title: "网络管理",
    },
    component: () => import("../views/SpecialInfo.vue"),
  },
  {
    path: "/ContactUs",
    name: "ContactUs",
    meta: {
      title: "联系我们",
    },
    component: () => import("../views/ContactUs.vue"),
  },
  {
    path: "/ReportCollection",
    name: "ReportCollection",
    meta: {
      title: "剧本征集",
    },
    component: () => import("../views/ReportCollection.vue"),
  },
  {
    path: "/ContactJob",
    name: "ContactJob",
    meta: {
      title: "招贤纳士",
    },
    component: () => import("../views/ContactJob.vue"),
  },
  {
    path: "/FilmsInfo",
    name: "FilmsInfo",
    meta: {
      title: "影剧详情",
    },
    component: () => import("../views/FilmsInfo.vue"),
  },
  {
    path: "/NewsInfo",
    name: "NewsInfo",
    meta: {
      title: "新闻详情",
    },
    component: () => import("../views/NewsInfo.vue"),
  },
  {
    path: "/MovServeCenter",
    name: "MovServeCenter",
    meta: {
      title: "拍摄服务中心",
    },
    component: () => import("../views/MovServeCenter.vue"),
  },
  {
    path: "/MovServeCenterInfo",
    name: "MovServeCenterInfo",
    meta: {
      title: "拍摄服务中心详情",
    },
    component: () => import("../views/MovServeCenterInfo.vue"),
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : "曲江影视";
  // if (to.name == 'SafeProduction') {
  //   window.location.href = 'https://www.mem.gov.cn/xw/ztzl/2024/d23gqgaqscy/';
  // }else{
    next();
  // }
});
export default router;
