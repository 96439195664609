<template>
  <nav id="Nav" class="navbar navbar-expand-md navbar-light">
    <div class="container-sm">
      <router-link class="navbar-brand" to="/"
        ><img src="../../public/img/logo/logo.png" alt=""
      /></router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li v-for="(item, index) in menu" :key="index" class="nav-item dropdown">
            <span class="nav-link" v-if="item.url" @click="oneSes(item.token, item.cn)">
              <div
                class="menuCN"
                :class="[item.token == tipMenu ? 'active' : '']"
                :data-token="item.token"
              >
                <router-link :to="item.url">{{ item.cn }}</router-link>
              </div>
              <div class="menuEN" :class="[item.token == tipMenu ? 'active' : '']">
                {{ item.en }}
              </div>
            </span>
            <div
              class="nav-link"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              v-if="!item.url"
              @click="oneSes(item.token, item.cn)"
            >
              <div
                class="menuCN"
                :class="[item.token == tipMenu ? 'active' : '']"
                :data-token="item.token"
              >
                {{ item.cn }}
              </div>
              <div class="menuEN" :class="[item.token == tipMenu ? 'active' : '']">
                {{ item.en }}
              </div>
            </div>
            <ul v-if="!item.url && item.children.length > 0" class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link
                v-for="(child, index) in item.children"
                :key="index"
                :to="child.url"
                :data-token="child.token"
                @click="twoSes(child.token, child.title)"
              >
                <!-- returnTop(child.token) -->
                <span
                  class="dropdown-item"
                  :class="[child.token == lightMenu ? 'active' : '']"
                  >{{ child.title }} <i class="fal fa-angle-right"></i
                ></span>
              </router-link>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref } from "vue";
export default {
  name: "Menu",
  data() {
    return {
      menu: ref([]),
      lightMenu: "",
      tipMenu: "",
      bread: {
        lightMenuTxt: "",
        tipMenuTxt: "",
      },
    };
  },
  created() {
    // this.menuClick();
    this.axios({
      method: "get",
      url:
        "https://cms.myhuiyi.com/API/V1/Website/Menus/default.aspx?ProjectsId=7&user=officialWebsite&password=VEFFbCCAPcApCsuKAjNgD1gS5YENnxBc",
    }).then((res) => {
      let data = res.data.data.DataList;
      sessionStorage.menu = JSON.stringify(data);
      for (let i = 0; i < data.length; i++) {
        if (data[i].MenusId == 0) {
          this.menu.push({
            cn: data[i].MenuName,
            en: data[i].AnotherName,
            token: data[i].id,
            url: data[i].Links,
            children: [],
          });
        } else {
          for (let j = 0; j < this.menu.length; j++) {
            if (data[i].MenusId == this.menu[j].token) {
              this.menu[j].children.push({
                title: data[i].MenuName,
                token: data[i].id,
                url: data[i].Links,
                sort: data[i].Paixu,
              });
            }
            this.menu[j].children = this.sortBykey(this.menu[j].children, "sort");
          }
        }
      }
    });

    this.tipMenu = sessionStorage.getItem("one");
    this.lightMenu = sessionStorage.getItem("two");
  },
  methods: {
    oneSes(id, tit) {
      sessionStorage.setItem("one", id);
      sessionStorage.setItem("oneName", tit);
    },
    twoSes(id, tit) {
      sessionStorage.setItem("two", id);
      sessionStorage.setItem("twoName", tit);
    },
    sortBykey(ary, key) {
      return ary.sort(function (a, b) {
        let x = a[key];
        let y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
  },
};
</script>
