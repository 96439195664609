<template>
  <div class="outLink">
    <div class="linkPic" v-for="(item, index) in links" :key="index">
      <a :href="item.url" title="{{item.title}}">
        <img :src="item.link" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "Links",
  data() {
    return {
      links: ref([]),
    };
  },
  created() {
    this.axios({
      method: "get",
      url:
        "https://cms.myhuiyi.com/API/V1/Website/Links/default.aspx?user=officialWebsite&password=VEFFbCCAPcApCsuKAjNgD1gS5YENnxBc&ClassifysId=7&MenusId=8&ProjectsId=7",
    }).then((res) => {
      let data = res.data.data.DataList;
      for (let i = 0; i < data.length; i++) {
        this.links.push({
          title: data[i].Title,
          url: data[i].Link,
          link: data[i].LogoUrl,
        });
      }
    });
  },
};
</script>
