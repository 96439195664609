<template>
  <footer class="container-lg">
    <span v-html="Details"></span>
  </footer>
</template>

<script>
import { ref } from "vue";
export default {
  name: "FooterBanner",
  setup() {
    return { Details: ref() };
  },
  created() {
    this.axios({
      method: "get",
      url:
        "https://cms.myhuiyi.com/API/V1/Website/Foots/default.aspx?user=officialWebsite&password=VEFFbCCAPcApCsuKAjNgD1gS5YENnxBc&ClassifysId=9&MenusId=8&ProjectsId=7",
    }).then((res) => {
      let data = res.data.data.DataList;
      this.Details = data[0].Details;
    });
  },
};
</script>
